import React from 'react'
import { CurrentUserProvider } from 'contexts/CurrentUser'

import BottomNav from 'components/BottomNav'
import LogOut from 'components/LogOut'
import SiteHeadMetadata from 'components/SiteHeadMetadata'

export const LogoutPage = () => {
  return (
    <CurrentUserProvider>
      <SiteHeadMetadata title="Logout" />
      <LogOut />
      <BottomNav />
    </CurrentUserProvider>
  )
}

export default LogoutPage
