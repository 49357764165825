import React, { FC, ReactNode, useContext, useEffect, useState } from 'react'
import useAsync from 'react-use/lib/useAsync'
import { RouteComponentProps } from '@reach/router'
import currentUserContext from 'contexts/CurrentUser/context'
import useClearLocalEntitiesDb from 'contexts/LocalEntities/useClearLocalEntitiesDb'

import PaddedFlexCentered from 'components/PaddedFlexCentered'
import Spinner from 'components/Spinner'
import useApi from 'hooks/useApi'
import { useTimeout } from 'hooks/useTimeout'

export const LogOut: FC<RouteComponentProps> = () => {
  const { clearEntitiesDb } = useClearLocalEntitiesDb()
  const [message, setMessage] = useState<ReactNode>(null)
  const { clearCurrentUser } = useContext(currentUserContext)
  const [doLogout, setDoLogout] = useState<boolean>(false)
  const [doClearDb, setDoClearDb] = useState<boolean>(false)
  const {
    isLoading,
    error,
    statusCode,
  } = useApi<string>(doLogout ? 'v1/authentication/signout' : undefined, undefined, {
    requestOptions: {
      method: 'POST',
    },
  })

  useEffect(() => {
    setDoClearDb(true)
  }, [])

  const { value: entitiesCleared } = useAsync(async () => {
    if(doClearDb) {
      await clearEntitiesDb()
      setDoLogout(true)
      return true
    }
  }, [doClearDb])

  useEffect(() => {
    if(entitiesCleared) {
      setMessage('Clearing local user...')
      setDoLogout(true)
    }
  }, [entitiesCleared])

  useEffect(() => {
    if(isLoading) {
      setMessage('Logging you out...')
    }
  }, [isLoading])

  useTimeout(() => {
    clearCurrentUser()
    window.sessionStorage.clear()
    window.location.href = '/signin'
  }, statusCode === 200 ? 200 : null)

  if(error) {
    throw error
  }

  return (
    <PaddedFlexCentered>
      <h1>👋</h1>
      <Spinner size={22} />
      <div>
        <small>{message}</small>
      </div>
    </PaddedFlexCentered>
  )
}

export default LogOut
